<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center flex-grow-1">
                    <h1><template>{{ $t('messages') }}</template></h1>
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                    ...Additional.map((item) => {
                        return {
                            ...item
                        }
                    })
                ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="document"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template  v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in topicItems"
                    :item="item"
                    :key="item.id"
                >
                </ListItem>
            </template>
            <template v-else>
                <div>
                    <v-row>
                        <v-col cols="12" class="px-0 mx-0" >

                            <v-list two-line class="background_color_transparent">
                                <v-list-item-group v-model="selected">
                                    <v-list-item
                                        v-for="(item, i) in topicItems"
                                        :key="i"
                                        :class="'rounded-sm  ' + item.class + ' ' + ($vuetify.breakpoint.xsOnly ? 'pl-15' : 'px-3')"
                                        class="mb-5"
                                        @click="openChat(item)"
                                    >

                                        <template v-slot:default="{ active }">
                                            <v-app-bar
                                                elevation="0"
                                                absolute
                                                class="my-5 py-0"
                                                height="0"
                                                v-if="!($vuetify.breakpoint.xsOnly)"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-icon v-if="item.active">${{'lockOpenIcon'}}</v-icon>
                                                <v-icon v-else >${{'lockIcon'}}</v-icon>

                                            </v-app-bar>
                                            <v-list-item-avatar size="58"    v-if="!($vuetify.breakpoint.xsOnly)">
                                                <img v-if="item.last_user.photo " :src="item.last_user.photo" >
                                                <v-icon v-else  size="26" >
                                                    ${{'settingsIcon'}}
                                                </v-icon>
                                            </v-list-item-avatar>



                                            <v-list-item-content class="ml-6">
                                                <v-row v-if="($vuetify.breakpoint.xsOnly)" class="my-0 py-0">
                                                    <v-col cols="12" style="position: absolute;top:3px;left:-3px">
                                                        <v-avatar size="58" >
                                                            <img v-if="item.last_user.photo " :src="item.last_user.photo" >
                                                            <v-icon v-else  size="26" >
                                                                ${{'settingsIcon'}}
                                                            </v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" style="position: absolute; bottom: 3px; left: 12px">
                                                        <v-icon  size="35" v-if="item.active">${{'lockOpenIcon'}}</v-icon>
                                                        <v-icon v-else size="50" >${{'lockIcon'}}</v-icon>

                                                    </v-col>
                                                </v-row>
                                                <v-row >
                                                    <v-col cols="12"  md="4" sm="4" lg="6"  >
                                                        <v-list-item-title  v-text="item.title"></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            style="max-width: 400px"
                                                            :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                            v-if="(item.last_message && item.last_message.type && item.last_message.type==='text') ">
                                                            <span >{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                                                            {{ item.last_message.value }}

                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            style="max-width: 400px"
                                                            :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                            v-if="(item.last_message && item.last_message.type && item.last_message.type==='file') ">
                                                            <span>{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                                                            {{item.last_message.file_name}}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            style="max-width: 400px"
                                                            :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                            v-if="(item.last_message && item.last_message.type && item.last_message.type==='image') ">
                                            <span >{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}
                                                <v-img max-width="100" :src="item.last_message.value ? item.last_message.value : userAvatar" ></v-img>
                                            </span>
                                                        </v-list-item-subtitle>
                                                    </v-col>
                                                    <v-col cols="12"  md="4" sm="4" lg="3" >
                                                        <v-list-item-title >
                                                            {{$t('last_message')}}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle >
                                                            {{item.last_date}}
                                                        </v-list-item-subtitle>
                                                    </v-col>
                                                    <v-col cols="12" md="4" sm="4" lg="3">
                                                        <v-list-item-title >
                                                            {{$t('application_status')}}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle  >
                                                            {{item.bot ? $t('system_message') : (item.active ? $t('open_application') : $t('closed_application')) }}
                                                        </v-list-item-subtitle>
                                                    </v-col>
                                                </v-row>


                                            </v-list-item-content>

                                        </template>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </div>
                <div class="main-pagination d-flex flex-row justify-end">
                    <SelectInput
                        class="main-pagination__per-page"
                        id="pagination-variants"
                        :value="options.itemsPerPage"
                        :items="perPageItems"
                        :label="$t('items_per_page')"
                        :disabled="loading"
                        @input="onPerPageChange($event)"
                        hide-label
                    ></SelectInput>
                    <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
                </div>
            </template>
        </template>

        <template v-slot:side>
            <v-list  class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link :to="item.url" :class="{'active': isSameRouteWithQueryParams($route, item.url)}" >
                        <v-list-item-title v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
</PageLayout>











</template>
<script>

    import { mapGetters } from "vuex"
    import FilterView from "@/plugins/mixins/FilterView";
    import ResourceView from "@/plugins/mixins/ResourceView";
    import NavMixin from "@/plugins/mixins/Nav";

    import FilterComponent from "@/components/FilterComponent.vue";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
    import ListItem from "@/components/Topic/ListItem.vue";
    import SelectInput from "@/components/Form/SelectInput.vue";

    export default {
        name: "Topics",
        mixins: [
            FilterView,
            ResourceView,
            NavMixin
        ],
        components: {
            FilterMobileLinks,
            FilterComponent,
            PageLayout,
            ListItem,
            SelectInput
        },
        data() {
            return {
                filter_show: false,
                entityName: null,
                no_data: false,
                selected: [],
                topic_item: {},
                topic_uuid: null,
                date: null,
                dateDialog: false,
                client: null,
                clientItems: [],
                clientSearching: null,
                loadingClients: false,



                topic: null,
                topicItems: [],
                active: 1,
                sortBy: "updated_at",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalTopics: 0,
                totalStorages: 0,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['updated_at'],
                    sortDir: false
                },
                loading: false,
                headers: [

                    {
                        text: this.$t('name'),
                        align: "left",
                        sortable: true,
                        value: "title"
                    },
                    {
                        text: this.$t('client'),
                        align: "left",
                        sortable: true,
                        value: "client.name"
                    },
                    {
                        text: this.$t('date_created'),
                        align: "left",
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active",
                        width: 110,
                    },
                    {
                        text: this.$t('open_chat'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                    {
                        text: '',
                        value: 'data-table-expand',
                        width: 40,
                        class: "handle",
                    },
                ],
                optionsTopic: {
                    startTimeout: 500,
                    useKeyboardNavigation: false,
                },
                group2: true
            };
        },
        computed: {
            ...mapGetters(['lang','userAvatar', 'itemsPerPage', 'perPageItems']),
            Additional: function () {
                return [
                    {
                        'title': this.$t('open'),
                        'count': '-',
                        'url': {
                            path: `/topic`,
                            query: {
                                filter: {
                                    active: {
                                        eq: '1'
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('close'),
                        'count': '-',
                        'url': {
                            path: `/topic`,
                            query: {
                                filter: {
                                    active: {
                                        eq: '0'
                                    }
                                }
                            }
                        }
                    }
                ]
            }

        },
        mounted() {
            this.init();
        },

        methods: {
            async init() {
                await this.getTopics()
            },
            async getTopics() {
                this.loading = true;
                const {
                    sortBy,
                    sortDir: sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc !== undefined) {
                    params.sortDir = sortDesc ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    params.page = page
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.storage) {
                    params.storage = this.storage;
                }


                if (this.filterValues) {
                    params.fltr = this.filterValues;
                }

                await this.$http
                    .get("client/topic", {
                        params: params,
                    })
                    .then(res => {
                        this.topicItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalStorages = res.body.meta.total
                        this.pageCount = res.body.meta.last_page


                    })
                    .catch(err => {
                        this.topicItems = []
                        this.totalStorages = 0
                        this.$toastr.error(this.$t('failed_to_get_list_topics'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            openChat(item){
                if(item.company_info === true){
                    this.$router.push({
                        name: 'company.edit',
                        params: {
                            uuid: item.uuid
                        }
                    })
                }
                else {
                    this.$router.push({
                        name: 'topic.messages',
                        params: {
                            uuid: item.uuid
                        }
                    })
                }

            }

        }
    }
</script>
