<script>
import User from "@/components/User.vue";

export default {
    name: "TopicListItem",
    components: {User},

    props: {
        item: {
            type: Object,
            required: true
        }
    },



}
</script>

<template>
    <div class="item">

        <div class="item__header">
            <div class="item__status small-text" :style="{ backgroundColor: item.color }"> {{item.bot ? $t('system_message') : (item.active ? $t('open_application') : $t('closed_application')) }}</div>
            <div class="item__created_at text-color-light small-text">{{ item.last_date }}</div>
        </div>

        <div class="item__description">
            <div class="item__id text-color-light small-text">#{{ item.id }}</div>
            <div class="item__name">
                <router-link  text plain :to="'/topic/' + item.uuid " class="cursor-pointer font_weight_500 button_link">
                    {{ item.title }}
                </router-link>
            </div>
            <div v-if="item.last_message" class="item__note text-color-light">

                <div
                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='text') ">
                    <span>{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                    {{ item.last_message.value }}
                </div>
                <div
                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='file') ">
                    <span>{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                    {{item.last_message.file_name}}
                </div>
                <div
                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='image') ">
                    <span >{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}
                        <v-img max-width="100" :src="item.last_message.value ? item.last_message.value : userAvatar" ></v-img>
                    </span>
                </div>
            </div>

        </div>



        <div class="item__data" >
            <div class="text-info" >
                <div class="text-info__label">
                    <div>{{$t('last_message')}}</div>
                </div>
                <div class="text-info__value">
                    {{item.last_date }}
                </div>
            </div>
        </div>


        <div class="item__footer">
            <User :item="item.last_user" />
        </div>
    </div>
</template>
